import { injectable, inject } from 'inversify';
import { IUpdateDoctorModel, IUpdatePatientModel, type IUserApi, UserApiSymbol } from '../api';
import { AuthStoreSymbol, type IAuthStore, type IUserStore, UserStoreSymbol } from '../stores';

export interface IUserService {
  loadMe(): Promise<void>;

  updateDoctor(data: IUpdateDoctorModel): Promise<void>;
  updatePatient(data: IUpdatePatientModel, uid: string | null): Promise<void>;
}

export const UserServiceSymbol = Symbol.for('IUserService');

@injectable()
export class UserService implements IUserService {
  @inject(UserApiSymbol)
  private userApi!: IUserApi;

  @inject(UserStoreSymbol)
  private userStore!: IUserStore;

  @inject(AuthStoreSymbol)
  private authStore!: IAuthStore;

  public async loadMe(): Promise<void> {
    const user = await this.userApi.getMe();
    return this.userStore.setCurrentUser(user);
  }

  public async updateDoctor(data: IUpdateDoctorModel): Promise<void> {
    let user = this.userStore.currentUser;
    if (!user?.uid) {
      return;
    }
    user = await this.userApi.putDoctor(user?.uid, data);
    this.userStore.setCurrentUser(user);
  }

  public async updatePatient(data: IUpdatePatientModel, patientUid: string | null): Promise<void> {
    if (!patientUid) return;
    let user = this.userStore.currentUser;
    if (!user?.uid) return;

    user = await this.userApi.putPatient(user?.uid, patientUid, data);
    this.userStore.setCurrentUser(user);
  }
}
