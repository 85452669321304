import React, { useCallback } from 'react';
import TextField from '@mui/material/TextField';

export interface FieldProps {
  value?: string | null;
  errorText?: string;
  onChange?(value: string | null): void;
}

export const CustomTextField = ({
  value,
  errorText,
  onChange,
  label
}: FieldProps & { label: string } & { maxRows: string | number }) => {
  const handleChange = useCallback(({ target }) => onChange?.(target.value), [onChange]);
  return (
    <TextField
      fullWidth
      onChange={handleChange}
      value={value}
      error={!!errorText}
      helperText={errorText ?? ''}
      label={label}
      variant="outlined"
      sx={{ marginBottom: '10px' }}
    />
  );
};
