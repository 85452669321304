import Container from '@mui/material/Container';
import styled from 'styled-components';
import {baseTheme} from '../../../GlobalStyle/GlobalStyle';


export const SuccessComponent = () => {
  return (
    <ThankYouWrapper>
      <Container
        maxWidth="sm"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <ThankYouMessage>
          <WordThankYou>Thank you!</WordThankYou> Your documents have been
          successfully uploaded and are currently undergoing verification. You
          will receive a notification to your email once the verification
          process is complete.
        </ThankYouMessage>
        <StripContainer />
      </Container>
    </ThankYouWrapper>
  );
};

const ThankYouWrapper = styled.div`
    margin-top: 400px;
  `;
const ThankYouMessage = styled.div`
  font-size: 22px;
  color: ${baseTheme.colors.__c_dark_black_basic};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const StripContainer =styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid ${baseTheme.colors.__c_dark_purple_general};
  width: 600px;
  margin-top: 20px;
`;
const WordThankYou =styled.div`
  color: ${baseTheme.colors.__с_turquoise_general};
  font-size: 25px;
  margin-bottom: 15px;
`;
