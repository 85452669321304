import { injectable, inject } from 'inversify';
import {
  ContactUsApiSymbol,
  IContactUsResponsData,
  IContactUsSubmitData,
  type IContactUsApi
} from '../api';

export interface IContactUsService {
  submit(data: IContactUsSubmitData): Promise<IContactUsResponsData>;
}

export const ContactUsServiceSymbol = Symbol.for('IContactUsService');

@injectable()
export class ContactUsService implements IContactUsService {
  @inject(ContactUsApiSymbol)
  private contactUsApi!: IContactUsApi;

  public async submit(data: IContactUsSubmitData): Promise<IContactUsResponsData> {
    return this.contactUsApi.submit(data);
  }
}
