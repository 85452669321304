const baseTheme = {
  colors: {
    __c_dark_black_basic: '#000000FF',
    __c_dark_purple_general: '#131951',
    __с_light_purple_general: '#8a7ac7',

    __с_light_blue_general_new: '#00A4EA',

    __с_turquoise_general: '#6fcbc9',
    __c_white: '#FFFFFF',
    __c_transparent: 'transparent',
    __c_gradient: 'linear-gradient(-45deg, #2b18dd 0%, #56a2ff 50%, #51eca5 100%)',
    __c_errorText: '#DB5241',

    textBlack: '#333',
    textWight: '#ffffff',
    textGrey: '#555',
    textDarkGrey: '#666',

    textGray_new: '#404B59',
    textDarkGray_new: '#2D2D2D',
    textBlack_new: '#212020',

    BorderColorLight_new: '#919EAB',
    BorderColorDark_new: '#919EAB52'
  },

  braickpoints: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tabletM: '768px',
    tabletL: '960px',
    laptop: '1024px',
    laptopL: '1439px',
    desktop: '1440px'
  }
};

export { baseTheme };
