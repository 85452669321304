import { Validator } from '@ts-awesome/validate/dist/interfaces';
import { isDefined } from '@ts-awesome/validate/dist/validators/utils';

interface IFile {
  name: string;
  size: number;
}

function isFile(value: any): value is IFile {
  return (
    !!value &&
    typeof value === 'object' &&
    typeof value.name === 'string' &&
    typeof value.size === 'number'
  );
}

export function file(options?: {
  formats?: string[];
  size?: number;
  name?: string;
}): Validator<unknown> {
  return function validateFile(value, key, attributes, globalOptions) {
    if (!isDefined(value)) return 'file not defined';
    if (!isFile(value) || !value.name) return 'invalid file';

    if (options?.size && value.size > options.size) {
      return 'file is too large';
    }

    const extension = value.name.split('.').pop();
    if (!extension) return;
    if (options?.formats && !options.formats.includes(extension)) {
      return 'file extension is not supported';
    }
  };
}
