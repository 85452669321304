import { inject, injectable } from 'inversify';
import { HttpTransportSymbol, type IHttpTransport } from '@ts-awesome/request';

export interface IContactUsSubmitData {
  name: string;
  email: string;
  comment: string;
  token: string;
}

export interface IContactUsResponsData {
  isTokenValid: boolean;
  message?: string;
}

export interface IContactUsApi {
  submit(data: IContactUsSubmitData): Promise<IContactUsResponsData>;
}

export const ContactUsApiSymbol = Symbol.for('IContactUsApi');

@injectable()
export class ContactUsApi implements IContactUsApi {
  @inject(HttpTransportSymbol) private http!: IHttpTransport;

  public submit({
    name,
    email,
    comment,
    token
  }: IContactUsSubmitData): Promise<IContactUsResponsData> {
    if (!name) {
      throw new Error('Name is missing');
    }
    if (!email) {
      throw new Error('Email is missing');
    }
    if (!comment) {
      throw new Error('Comment is missing');
    }
    if (!token) {
      throw new Error('Token is missing');
    }
    return this.http.post<IContactUsResponsData>('api/v1/contact-us', {
      body: { name, email, comment, token }
    });
  }
}
