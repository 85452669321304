import React from 'react';
import styled from 'styled-components';
import { baseTheme } from '../GlobalStyle/GlobalStyle';
import { Link } from 'react-router-dom';
import styles from '../layouts/foterStyle.module.css';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';

const Footer = () => {
  return (
    <FooterStyle>
      <ContainerFooter>
        <ImgLogoStyle src={require('../assets/logo_footer.png')} alt="logo" />

        <FooterContent>
          <Cms>
            <DescriptionText>
              Invia healthcare platform was founded by a practicing physician in order to provide
              safe, accessible and affordable medical care taking into account the dynamic lifestyle
              of patients. Invia is focused on connecting you with a licensed healthcare provider
              whenever you are in need.
            </DescriptionText>
          </Cms>

          <Cms>
            <ContactInfo>
              <ContactInfoTextContainer>
                <InfoTextContainer>
                  <LocalPhoneOutlinedIcon
                    sx={{
                      fontSize: 20,
                      color: baseTheme.colors.__с_light_blue_general_new,
                      marginRight: 2
                    }}
                  />

                  <InfoText>+1 8 (224) 306-20-50</InfoText>
                </InfoTextContainer>

                <InfoTextContainer>
                  <MailOutlineOutlinedIcon
                    sx={{
                      fontSize: 20,
                      color: baseTheme.colors.__с_light_blue_general_new,
                      marginRight: 2
                    }}
                  />

                  <InfoText>info@inviacare.com</InfoText>
                </InfoTextContainer>
              </ContactInfoTextContainer>
              <TermsContainer>
                <Link to={'terms-of-service'} className={styles.styleLinkTerms}>Terms of Service</Link>
                <Link to={'privacy-policy'} className={styles.styleLinPrivacy}>Privacy Policy</Link>
              </TermsContainer>
            </ContactInfo>
          </Cms>
        </FooterContent>

        <DescriptionTextCopyright>Copyright &copy; 2022 InviaCare corp.</DescriptionTextCopyright>
      </ContainerFooter>
    </FooterStyle>
  );
};

export { Footer };

const FooterStyle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  width: 100%
`;

const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;

  padding: 60px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    width: ${baseTheme.braickpoints.tabletM};
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    padding: 40px 30px;
  };
  @media (max-width: ${baseTheme.braickpoints.mobileL}) {
    width: ${baseTheme.braickpoints.mobileS};
    padding: 30px 16px;
  }
`;

const ImgLogoStyle = styled.img`
  padding-top: 53px;
  align-self: start;
  display: flex;
  margin: 0;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    flex-direction: column;
    width: 168px;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Cms = styled.div`
  width: calc((100%) / 2);
  margin: 0;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
`;

const DescriptionText = styled.p`
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 14px;
  margin-top: 20px;
  box-sizing: border-box;
  text-align: start;
  justify-content: end;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    flex-direction: column;
    align-items: center;
    text-align: start;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    justify-content: flex-start;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    // justify-content: space-between;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileL}) {
    flex-direction: column;
  }
`;


const InfoText = styled.p`
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
`;
const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  text-align: left;
  margin-top: 20px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    margin-right: 50px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    margin-right: 0,
  }
`;

const ContactInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;

  margin-left: 20%;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    margin-left: 0;
  }
`;

const TermsContainer = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: start;
  width: max-content;
`;

const DescriptionTextCopyright = styled.div`
  font-size: 12px;
  display: flex;
  width: 100%;
  margin: 43px 53px;
  color: ${baseTheme.colors.textGray_new};

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    flex-direction: column;
    text-align: start;
  }
`;
