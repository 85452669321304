import React, {FormEvent, useCallback} from 'react'
import {CssBaseline} from '@mui/material'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import styles from '../contact-us/contact-us.module.css'
import styled from 'styled-components'
import {useBloc} from '../../ioc/index'
import {DoctorProfileBloc} from './doctor-profile.bloc'
import {useParams} from 'react-router'
import {CustomTextField} from '../../components/custom-text-field/custom-text-field'
import {Observer} from 'mobx-react'
import {CustomUploader} from '../../components/custom-uploader/custom-uploader'
import {baseTheme} from '../../GlobalStyle/GlobalStyle'
import {ErrorComponent} from './components/error-component'
import {SuccessComponent} from './components/success-component'
import {required} from '@ts-awesome/validate';
import WhiteStarRating from './components/WhiteStarRating'

const DoctorMockImg = require('../../assets/doctor-mock.png')
const BackgroundMockImg = require('../../assets/doctorProfilebackground.png')
const speciality = require('../../assets/speciality.png')
const education = require('../../assets/education.png')


export const DoctorProfilePage = () => {

  const params = useParams()

  const bloc = useBloc(DoctorProfileBloc, params)

  const onSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    bloc.handleSubmit();
  }, [bloc])

  return <Observer>
    {() => {
      if (bloc.error) return <ErrorComponent message={bloc.errorMessage}/>
      if (bloc.submitted) return <SuccessComponent/>
      return (
        <GrandWrapper>
          <form autoComplete='off' onSubmit={onSubmit}>
            <GeneralWrapper>
              <CssBaseline/>
              <Container maxWidth='sm'
                         sx={{
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                           flexDirection: 'column'
                         }}>
                <Box sx={{
                  bgcolor: 'white',
                  height: '750px',
                  width: '600px',
                  border: '1px solid grey',
                  borderRadius: '12px',
                  display: 'flex', alignItems: 'center', justifyContent: 'start', flexDirection: 'column'
                }}>
                  <br/>
                  <br/>
                  <DoctorProfileStyle>Doctor Profile</DoctorProfileStyle>
                  <br/>
                  <br/>
                  <Box sx={{
                    bgcolor: 'white',
                    height: '400px',
                    width: '400px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}>
                    <CustomTextField maxRows={1} {...bloc.firstname} label={'FirstName*'}/>
                    <CustomTextField maxRows={1} {...bloc.lastname} label={'LastName*'}/>
                    <CustomTextField maxRows={1} {...bloc.education} label={'Education*'}/>
                    <CustomTextField maxRows={1} {...bloc.speciality} label={'Speciality*'}/>
                    <CustomTextField maxRows={4} {...bloc.bio} label={'Shirt Bio'}/>
                    <br/>
                    <CustomUploader {...bloc.photo}/>
                    <br/>
                    <CustomUploader {...bloc.document}/>
                    <br/>
                    <CustomStyleButton>
                      <Button
                        className={styles.buttonStyleCss}
                        type='submit'
                        variant={'outlined'}
                      > Submit </Button>
                    </CustomStyleButton>
                  </Box>
                </Box>
              </Container>
            </GeneralWrapper>
          </form>

          <DoctorPreviewWrapper>
            <ContainerDoctor>
              <ImgContainer>
                <Image>
                  {bloc.photo.value ? (
                    <img style={{width: 250}} src={URL.createObjectURL(bloc.photo.value)} alt='DoctorPhoto'/>
                  ) : (
                    <img style={{width: 250}} src={DoctorMockImg} alt='Doctor Mock'/>
                  )}
                </Image>
              </ImgContainer>
              <Name>
                {bloc.doctorName}
              </Name>
              <Name>
                {bloc.doctorSurname}
              </Name>
              <Speciality>
                <SpecialityTitle>
                  <img src={education} alt='education'/>
                </SpecialityTitle>
                <SpecialityData>{bloc.specialityDoctor}</SpecialityData>
              </Speciality>
              <Education>
                <EducationTitle>
                  <img src={speciality} alt='speciality'/>
                </EducationTitle>
                <EducationData>{bloc.educationDoctor}</EducationData>
              </Education>
              <WhiteStarRating/>
            </ContainerDoctor>
            <ContainerBio>
              <Bio>
                <BioData>{bloc.bioDoctor}</BioData>
              </Bio>
            </ContainerBio>
            <FooterDoctor/>
          </DoctorPreviewWrapper>
        </GrandWrapper>
      )
    }
    }
  </Observer>
}


const CustomStyleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Name = styled.div`
  font-size: 28px;
  text-align: center;
  font-weight: 400;
  color: ${baseTheme.colors.__c_white};
  width: 300px;
  text-transform: capitalize;
  //margin-bottom: 1px;
`
const GeneralWrapper = styled.div`
  margin-top: 20px;
`
const FooterDoctor = styled.div`
  margin-top: auto;
  background-image: url(${BackgroundMockImg});
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 20px;
  width: 100%;
`
const ContainerDoctor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${BackgroundMockImg});
  width: 100%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`
const ContainerBio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const DoctorProfileStyle = styled.div`
  margin-bottom: 80px;
  font-weight: bold;
  font-size: 22px;
`

const ThankYouWrapper = styled.div`
  margin-top: 400px;
`
const ThankYouComponent = styled.div`
  font-size: 22px;
  color: ${baseTheme.colors.__c_dark_black_basic};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const StripContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid ${baseTheme.colors.__c_dark_purple_general};
  width: 600px;
  margin-top: 20px;
`
const WordThankYou = styled.div`
  color: ${baseTheme.colors.__с_turquoise_general};
  font-size: 25px;
  margin-bottom: 15px;
`
const GrandWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`
const DoctorPreviewWrapper = styled.div`
  min-height: 750px;
  width: 400px;
  border: 1px solid grey;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  margin-left: 2%;
`
const ImgContainer = styled.div`
  margin-bottom: 10px;
  border-radius: 150px;
  overflow: hidden;
  aspect-ratio: 1;
  border-width: 4px;
  border-color: #404B59;
  margin-top: 10px;
`
const Image = styled.image`
  //width: 100px;
  //height: 100px;
  aspect-ratio: 1;
  align-self: center;
  justify-content: center;
`
const Education = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 5px;
  height: 100px;
  max-width: 350px;
  word-wrap: break-word;

`
const EducationTitle = styled.image`
  font-weight: 600;
  font-size: 16px;
`
const EducationData = styled.div`
  margin-top: 10px;
  font-weight: 200;
  font-size: 16px;
  color: ${baseTheme.colors.__c_white};
  text-transform: capitalize;
`
const Speciality = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
  margin-bottom: 5px;
  margin-top: 10px;
  max-width: 350px;
  max-height: 100%;
  word-wrap: break-word;


`
const SpecialityTitle = styled.image`
  font-weight: 200;
  font-size: 16px;
`
const SpecialityData = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${baseTheme.colors.__c_white};
  font-family: Be Vietnam sans-serif;
  text-transform: capitalize;
`
const Bio = styled.div`
  margin-top: 20px;
  max-width: 350px;
`
const BioData = styled.div`
  margin-left: 20px;
  font-size: 16px;
`