import styled from 'styled-components';
import {baseTheme} from '../../GlobalStyle/GlobalStyle';
import { useEffect, useState } from 'react';

const SeeADoctorPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <MakeYourBusinessStyle id={'/'}>
      <ContainerMakeYourBusiness>
        <Cms>
          <TitleText>
            See a doctor in person <br />
            without leaving your house.
          </TitleText>

          <DescriptionText>
            With Invia App you can manage your health conveniently with top quality care. Accessible
            and affordable 24/7.
          </DescriptionText>
        </Cms>

        <ImgPhone>
          { windowWidth < 667
            ? (<ImgApplePhone src={require('../../assets/iPhone_15_Pro_background_small.png')} alt="hero-mockup"/>)
            : (<ImgApplePhone src={require('../../assets/iPhone_15_Pro_background.png')} alt="hero-mockup"/>)
          } 
        </ImgPhone>

        <ImgPay>
          <FlexComingSoon>
            <ImgApplePay src={require('../../assets/appstore.png')} alt="playstore" />
          </FlexComingSoon>
        </ImgPay>
      </ContainerMakeYourBusiness>
    </MakeYourBusinessStyle>
  );
};

const ContainerMakeYourBusiness = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1110px;
  margin: 0 auto;
  flex-direction: column;
  padding: 60px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    width: ${baseTheme.braickpoints.tabletM};
    padding: 30px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    flex-direction: column;
    padding: 40px 30px;
  };
  @media (max-width: ${baseTheme.braickpoints.mobileL}) {
    width: ${baseTheme.braickpoints.mobileS};
    padding: 40px 16px;
  }
`;

const Cms = styled.div`
  width: calc((100%) / 2);
  margin: 80px;

  @media (max-width: ${baseTheme.braickpoints.laptopL}) {
    width: 100%;
    margin: 0 23px;
  }
`;

const MakeYourBusinessStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

const TitleText = styled.h1`
  color: ${baseTheme.colors.textBlack_new};
  font-size: 50px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 22px;
  letter-spacing: -3px;
  width: 100%;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    text-align: center;
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: normal
  };
  @media (max-width: ${baseTheme.braickpoints.mobileL}) {
    text-align: center;
    font-size: 29px;
    font-style: normal;
    font-weight: 800;
    line-height: normal
  };
`;

const DescriptionText = styled.p`
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 25px;
  font-weight: 400;
  box-sizing: border-box;


  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
font-size: 15px;
font-style: normal;

  };
  @media (max-width: ${baseTheme.braickpoints.mobileL}) {
font-size: 13px;
font-style: normal;
  };
`;

const ImgPhone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px
`;

const ImgPay = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const ImgApplePay = styled.img`
  height: 49px;
`;

const ImgApplePhone = styled.img`
  max-height: 400px;
  width: 100%;
  margin: 0;
  aligh-self: center;
`;

const FlexComingSoon = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
`;

export { SeeADoctorPage };
