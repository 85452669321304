import React from 'react';
import styled from 'styled-components';
import { baseTheme } from '../../GlobalStyle/GlobalStyle';

const TermsStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${baseTheme.colors.__c_dark_purple_general};
`;
const TermsStyleContent = styled.span`
  color: ${baseTheme.colors.__c_dark_purple_general};
`;

const Terms = () => {
  return (
    <TermsStyle>
      <TermsStyleContent>Page Terms of Service Under Construction</TermsStyleContent>
    </TermsStyle>
  );
};

export { Terms };
