/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import { baseTheme } from '../../GlobalStyle/GlobalStyle';

const Privacy = () => {
  const obligationsListItems = [
    'Send or otherwise transmit to or through the Websites any unlawful, infringing, harmful, harassing, defamatory, threatening, hateful or otherwise objectionable material of any kind, any material that can cause harm or delay to the Websites or computers of any kind, and any unsolicited advertising, solicitation or promotional materials',
    'Restrict or inhibit any person from using the Websites',
    'Reverse engineer, disassemble or decompile any section or technology on the Websites, or attempt to do any of the foregoing',
    'Gain unauthorized access to the Websites, to other users accounts, names, personally identifiable information or other information, or to other computers or websites connected or linked to the Websites',
    'Launch or use any automated system, including without limitation, "robots," "spiders," or "offline readers," that access the Websites in a manner that sends more request messages to our servers in a given period of time than a human can reasonably produce in the same period by using a conventional web browser',
    'Post, transmit or otherwise make available any virus, worm, spyware or any other computer code, file or program that may or is intended to damage or hijack the operation of any hardware, software or telecommunications equipment',
    'Alter or modify any part of the content or services offered on or through the Websites',
    'Collect any information from the Websites for any purpose other than individual personal use',
    'Attempt, assist or permit any persons in engaging in any of the activities described above',
  ];

  return (
    <PrivacyScreenContainer>
      <Title>
        LEGAL DISCLAIMER
      </Title>

      <SubTitle>
        Effective Date: [insert date]
      </SubTitle>

      <Article>
        InviaCare, Inc., a Delaware corporation, on its own behalf and its affiliates and/or wholly owned 
        subsidiaries ("InviaCare", "we", "us" "our"), makes certain information available to you and/or 
        facilitates your access to expert medical service providers ("InviaCare Services").
        This Disclaimer applies to visitors to the following publicly available websites that are owned 
        and operated by InviaCare: [insert website] and their associated domains and mobile applications
        (collectively, the "Websites").
        For the purposes of this Disclaimer, "you" and "your" means you as the visitor or user of the 
        Websites. By using the Websites, you expressly agree to the terms and conditions set out in this 
        Disclaimer. If you do not agree to this Disclaimer, you should immediately cease all use of and 
        access to the Websites.
      </Article>

      <ArticleTitle>
        InviaCare is NOT a Medical Services Provider
      </ArticleTitle>

      <Article>
        InviaCare is not a medical services provider, nor insurance provider nor are we a prescription 
        fulfillment warehouse. InviaCare's role is limited to making certain information available to you 
        and/or facilitate your access to expert medical service providers. InviaCare is independent from 
        any healthcare providers who provide any medical services to you through the access to the 
        Websites or elsewhere and is not responsible for such healthcare service providers' acts, 
        omissions or for any content of the communications made by them. InviaCare does not engage in
        the practice of medicine. InviaCare does not in any way confirm, guarantee or certify any 
        qualifications, compliance, experience, expertise or any medical Board licensing or compliance 
        of any healthcare service providers that you get access to through the Websites or elsewhere. 
      </Article>

      <Article>
        The Websites do not provide medical advice and do not create a healthcare provider/patient 
        relationship between you and InviaCare or otherwise. Any services provided by or content 
        accessed from the Websites are for informational purposes only and are not intended to replace 
        the advice of your healthcare provider. Please consult your doctor or other qualified healthcare 
        provider if you have any questions about a medical condition. Call 911 or Your doctor for all
        medical emergencies. INVIACARE IS NOT RESPONSIBLE OR LIABLE FOR ANY 
        ADVICE, COURSE OF TREATMENT, DIAGNOSIS OR ANY OTHER INFORMATION, 
        SERVICES OR PRODUCTS THAT YOU MAY OBTAIN THROUGH THE WEBSITES.
      </Article>

      <ArticleTitle>
        Your Obligations
      </ArticleTitle>

      <Article>
        By using the Websites, you agree to comply with all laws applicable to your use of the Websites,
        you will not interfere with a third party's use and enjoyment of the Websites, and you will not
        interfere with or disrupt InviaCare's or its vendors' security measures. You agree not to:

        <Article>
          {obligationsListItems.map((option, index) => (
            <MapObligationListItem key={index}>
              {option}
            </MapObligationListItem>
          ))}
        </Article>
      </Article>

      <ArticleTitle>
        Intellectual property rights and content
      </ArticleTitle>

      <Article>
        InviaCare is the sole and exclusive owner of the Websites and their content, including all 
        copyright, patent, trademark, trade secret and other ownership and intellectual property rights, in 
        and to the Websites and any related materials and documentation. No title or ownership of the 
        Websites or any portion thereof is transferred to you hereunder. InviaCare reserves all rights not 
        expressly granted hereunder. You agree not to change or delete any copyright or proprietary 
        notice related to materials downloaded from the Websites.
      </Article>

      <Article>
        You may provide input, comments, questions or suggestions ("Submissions"). You acknowledge 
        and agree that InviaCare may use any Submissions without any obligation to you and you hereby
        grant InviaCare a worldwide, perpetual, irrevocable, royalty-free, transferable license to 
        reproduce, display, perform, distribute, publish, modify, edit or otherwise use such Submissions 
        as InviaCare may deem appropriate, without restriction, for any and all commercial and/or non-
        purposes, in its sole discretion.
      </Article>

      <Article>
        InviaCare takes no responsibility and assumes no liability for Submissions, and you 
        acknowledge and agree that you have all rights necessary to make the Submissions. You 
        understand that InviaCare does not endorse Submissions of others and is not responsible for the 
        content of such Submissions. InviaCare disclaims any and all liability for any information, 
        including but without limitation, any medical and health treatment information set forth in a 
        Submission.
      </Article>

      <ArticleTitle>
        External links
      </ArticleTitle>

      <Article>
        The Websites may contain links to third-party websites. Linked sites are not under the control of 
        InviaCare, and InviaCare is not responsible for the content of any linked site. Links are provided 
        as a convenience only, and a link does not imply that InviaCare endorses, sponsors, or is 
        affiliated with the linked site. Your use of third-party websites is at your own risk and subject to 
        the terms and conditions of use for such sites; this Disclaimer does not apply to other websites. 
        InviaCare disclaims any and all liability for any information, including but without limitation, 
        any medical and health treatment information set forth on linked sites
      </Article>

      <ArticleTitle>
        No endorsements
      </ArticleTitle>

      <Article>
        Reference to any product, recording, event, process, publication, service, or offering of any third 
        party by name, trade name, trademark, service mark, company name or otherwise does not 
        constitute or imply the endorsement or recommendation of such by InviaCare. Any views 
        expressed by third parties on the Websites are solely the views of such third party and InviaCare 
        assumes no responsibility for the accuracy or veracity of any statement made by such third party
      </Article>

      <ArticleTitle>
        No Warranties
      </ArticleTitle>

      <Article>
        The Websites are provided on an "as is" and "as available" basis and without warranties of any 
        kind, either express or implied. To the fullest extent permissible pursuant to applicable law, 
        InviaCare disclaims all representations, warranties, and conditions, express or implied, including,
        but not limited to, implied condition or warranties of merchantability and fitness for a particular 
        purpose. InviaCare does not warrant that the Websites will be uninterrupted or error-free, that 
        defects will be corrected or that the Websites or the server that makes it available are free of 
        viruses or other harmful components
      </Article>

      <Article>
        InviaCare makes no guarantees and disclaims any implied warranty or representation about its 
        accuracy, relevance, timeliness, completeness, or appropriateness of any content posted on the 
        Website for a particular purpose. InviaCare assumes no liability arising from or relating to the 
        delay, failure, interruption, or corruption of any data or other information transmitted in 
        connection with use of the Websites
      </Article>

      <Article>
        InviaCare is not responsible for any costs or harm incurred by you as a result of fraudulent 
        schemes perpetuated by third parties via the Internet, including any schemes involving phishing 
        websites holding themselves out to be the Websites or phishing emails designed to appear to be 
        sent from InviaCare.
      </Article>

      <ArticleTitle>
        Indemnification and limitation of liability
      </ArticleTitle>

      <Article>
        You agree to indemnify, defend and hold harmless InviaCare, its clients, and its vendors and 
        suppliers and their respective affiliates, employees, officers, directors, agents, shareholders, and 
        representatives of each from any liability, loss, claim, suit, damage, and expense (including 
        reasonable attorneys' fees and expenses) related to (i) your violation of this Disclaimer, (ii) your 
        use, or misuse, of the Websites or InviaCare Services, and/or (iii) your posting of Submissions
      </Article>

      <Article>
        InviaCare SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, 
        SPECIAL, EXEMPLARY, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT 
        OF YOUR USE OF, OR INABILITY TO USE, THE WEBSITE
      </Article>

      <Article>
        This limitation reflects an informed voluntary allocation of risk; such allocation represents a 
        material part of this Disclaimer. You agree that the limitations of liabilities are fair and 
        reasonable in the circumstances.
      </Article>

      <ArticleTitle>
        Personal information and privacy policy
      </ArticleTitle>

      <Article>
        InviaCare may collect, use and disclose personal information about you through your use of the 
        Website. Please review the Web and Mobile Privacy Policy to find out how we collect, use and 
        disclose your personal information that is collected
      </Article>

      <ArticleTitle>
        Modifications to this disclaimer
      </ArticleTitle>

      <Article>
        InviaCare may, at any time and from time to time, amend this Disclaimer. Any changes will be 
        effective immediately upon posting. You agree to review the Disclaimer periodically and your 
        use of the Websites following any such change constitutes your agreement to follow and be 
        bound by this Disclaimer as amended
      </Article>
    </PrivacyScreenContainer>
  );
};

const PrivacyScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  display: flex;
  flex-direction: column;
  text-align: start;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    padding: 30px;
  };

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    padding: 16px;
  };
`;

const Title = styled.h2`
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 30px;
  box-sizing: border-box;
  display: flex;
  padding: 0;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    font-size: 25px;
  };

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    20px;
  };
`;

const SubTitle = styled.h3`
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 25px;
  box-sizing: border-box;
  padding: 0

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    font-size: 20px;
  };

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    font-size: 15px;
  };
`;

const ArticleTitle = styled.h4`
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 20px;
  box-sizing: border-box;
  padding: 0
  text-align: center;


  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    font-size: 15px;
  };

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    font-size: 10px;
  };
`;

const Article = styled.h5`
  color: ${baseTheme.colors.textGray_new};
  font-size: 15px;
  box-sizing: border-box;
  padding: 0,
  text-align: start;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    font-size: 12px;
  };

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    font-size: 8px;
  };
`;

const MapObligationListItem = styled.li`
  list-style-type: circle;
  padding-top: 2px;
`;




export { Privacy };
