import React from 'react';

import { CustomeVideoPlayer } from '../../components/custom-video-player/custome-video-player';
import styled from 'styled-components';
import { baseTheme } from '../../GlobalStyle/GlobalStyle';

export const VideoPresentationPage = () => {
  return (
    <VideoPageWrapperStyle>
      <CustomeVideoPlayer />
    </VideoPageWrapperStyle>
  );
};

const VideoPageWrapperStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${baseTheme.colors.__c_white};
  margin-top: 40px;
  margin-bottom: 60px;

  @media (max-width: ${baseTheme.braickpoints.desktop}) {
    height: 500px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    height: 300px;
    margin-bottom: 0;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileL}) {
    height: 200px;
  }
`;
