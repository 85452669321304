import React from 'react';

import ReactPlayer from 'react-player';

export const CustomeVideoPlayer = () => {
  return (
    <ReactPlayer 
      url='https://vimeo.com/902993593?share=copy'
      controls volume={1}
      width='77vw'
      height='70vh'
      playing={false}
    />
  );
};
