import styled from 'styled-components';
import { baseTheme } from '../../GlobalStyle/GlobalStyle';

const AppBenefitsDoctor = () => {
  return (
    <AppBenefitsDoctorStyle id={'x2'}>
      <AppBenefitsDoctorInterface>
        <CmsImg>
          <ImgPhoneBox>
            <ImgPhone src={require('../../assets/phone_location_screen.png')} alt="mockup-watch" />
          </ImgPhoneBox>
        </CmsImg>

        <Cms>
          <CmsColumn>
            <PageTitle>For doctors</PageTitle>
            <PageSubtitle>
              Enhance your healthcare practice using advanced technology and mobile apps. 
              Gain control of your time and income, breaking free from paycheck dependencies. 
              Empower business growth, challenge norms, and achieve a perfect work-life balance with our healthcare app.
            </PageSubtitle>

            <CmsRow>
              <ImgIcon src={require('../../assets/user_friendly.png')} alt="easy-to-use-icon" />
              <CardContent>
                <TitleText> User friendly </TitleText>
                <DescriptionText>
                  User friendly design. Improve efficiency by reducing time completing
                  administrative task. Flexible schedule.
                </DescriptionText>
              </CardContent>
            </CmsRow>

            <CmsRow>
              <ImgIcon src={require('../../assets/communication.png')} alt="easy-to-use-icon" />
              <CardContent>
                <TitleText> Walk anywhere </TitleText>
                <DescriptionText>
                  Reach more patients and build your own private practice. Expand your patient
                  circle. Work anywhere.
                </DescriptionText>
              </CardContent>
            </CmsRow>

            <CmsRow>
              <ImgIcon src={require('../../assets/24_hours_per_day.png')} alt="easy-to-use-icon" />
              <CardContent>
                <TitleText> Available anytime </TitleText>
                <DescriptionText>
                  Healthcare available anytime and anywhere regardless of your location.
                </DescriptionText>
              </CardContent>
            </CmsRow>
          </CmsColumn>
        </Cms>
      </AppBenefitsDoctorInterface>
    </AppBenefitsDoctorStyle>
  );
};

const AppBenefitsDoctorStyle = styled.div`
  width: 100%;
  height: 650px;
  display: flex;
  align-items: center;
  margin: 0 auto;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    height: 100%;
    margin-bottom: 40px;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileM}) {
    margin-bottom: 20px;
  }
`;

const AppBenefitsDoctorInterface = styled.div`
  display: flex;
  align-items: center;
  width: 1100px;
  margin: 0 auto;
  padding: 70px 60px;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    flex-direction: column;
    padding: 70px 30px;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileM}) {
    width: 320px;
    padding: 60px 0;
  }
`;

const Cms = styled.div`
  display: flex;
  width: 65%;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    width: 100%;
  }
`;

const CmsImg = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
`;

const CmsColumn = styled.div`
  flex-direction: column;
`;

const PageTitle = styled.text`
  color: ${baseTheme.colors.textDarkGray_new};
  display: flex;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  flex-direction: column;
  margin-bottom: 8px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    font-size: 35px;
    font-weight: 600;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileM}) {
    font-size: 28px;
  }
`;

const PageSubtitle = styled.text`
  color: ${baseTheme.colors.textDarkGray_new};
  display: flex;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin-bottom: 25px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    font-size: 18px;
    font-weight: 400;
  }
`;

const CmsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #f0f5fd;
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(51, 102, 255, 0.05);
`;

const ImgIcon = styled.img`
  width: 50px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    width: 45px;
  }
`;

const CardContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: ${baseTheme.braickpoints.mobileL}) {
    width: 200px;
  }
`;

const TitleText = styled.h2`
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin: 0 0 6px;
  margin-left: 20px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    font-size: 17px;
    font-weight: 600;
  }
`;

const DescriptionText = styled.text`
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin-left: 20px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    font-size: 13px;
    font-weight: 300;
  }
`;

const ImgPhoneBox = styled.div`
  width: 420px;
  background-image: url(${require('../../assets/ellipse_background.png')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: ${baseTheme.braickpoints.laptop}) {
    background-image: unset;
    width: auto;
  }
`;

const ImgPhone = styled.img`
  height: 690px;

  @media (max-width: ${baseTheme.braickpoints.laptopL}) {
    height: 590px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    height: 490px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    display: none;
  }
`;

export { AppBenefitsDoctor };
