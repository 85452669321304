import React, { useCallback } from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';

export interface IUploader {
  value?: any;
  accept: string;
  title: string;
  onChange?(value: any): void;
  errorText?: string;
}

export const CustomUploader = ({ value, onChange, accept, title, errorText }: IUploader) => {
  const handleChange = useCallback(({ target }) => onChange?.(target.files[0]), [onChange]);

  return (
    <div>
      <div>{title}</div>
      <Stack direction="row" justifyContent="center" spacing={2}>
        <IconButton color="primary" aria-label="upload picture" component="label">
          <input hidden onChange={handleChange} accept={accept} type="file" />
          <UploadFileIcon />
        </IconButton>
      </Stack>
      {value ? <div style={{ fontSize: '0.75em' }}>{value?.name}</div> : null}
      {errorText ? <div style={{ color: 'red', fontSize: '0.75em' }}>{errorText}</div> : undefined}
    </div>
  );
};
