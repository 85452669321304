import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

export function MainLayout() {
  return (
    <>
      <PageContainer>
        <Outlet />
      </PageContainer>
    </>
  );
}

const PageContainer = styled.div`
  display: block;
  flex: 1 1 auto;
  text-align: center;
  position: relative;
`;
