import React, { useState } from 'react';
import styled from 'styled-components';

interface StarIconProps {
  active: boolean;
}

const StarIcon = styled.span<StarIconProps>`
  font-size: 24px;
  cursor: pointer;
  color: ${(props) => (props.active ? 'gold' : 'white')};
  margin-right: 5px; /* Встановіть бажану відстань між зірками */
  margin-bottom: 8px;
`;

const RatingContainer = styled.div`
  display: flex;
`;

const ExampleComponent = () => {
  const [activeStars, setActiveStars] = useState(4); // Початковий стан активних зірок

  const handleStarClick = (index) => {
    setActiveStars(index);
  };

  return (
    <RatingContainer>
      {[1, 2, 3, 4, 5].map((index) => (
        <StarIcon
          key={index}
          active={index <= activeStars}
          onClick={() => handleStarClick(index)}
        >
          ★ {/* Це символ зірки */}
        </StarIcon>
      ))}
    </RatingContainer>
  );
};

export default ExampleComponent;
