import React, { FormEvent, useRef } from 'react';
import Box from '@mui/material/Box';
import { Observer } from 'mobx-react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Button, useMediaQuery } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { useBloc } from '../../ioc/index';
import { ContactUsBloc } from './contact-us.bloc';
import { baseTheme } from '../../GlobalStyle/GlobalStyle';
import { Recaptcha, TRecaptchaRef } from 'components/recaptcha';
import { CustomTextField } from '../../components/custom-text-field/custom-text-field';

const recaptchaComponentId = 'invia-recaprcha';

export const ContactUsPage = () => {
  const params = useParams();
  const matches = useMediaQuery('(min-width:550px)');

  const recaptchaRef = useRef<TRecaptchaRef>(null);

  const bloc = useBloc(ContactUsBloc, params);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const token = await recaptchaRef?.current?.execute();

    bloc.handleSubmit(token);
  };

  return (
    <Observer>
      {() => (
        <GoogleReCaptchaProvider
          reCaptchaKey={bloc.recaptchaSiteKey}
          container={{
            element: recaptchaComponentId,
            parameters: {
              badge: 'inline'
            }
          }}>
          <ContactUsStyle>
            <ContainerContactUs id={'x3'}>
              <CmsImg>
                <ImgPhoneBox>
                  <ImgPhone
                    src={require('../../assets/phone_location_screen.png')}
                    alt="mockup-watch"
                  />
                </ImgPhoneBox>
              </CmsImg>

              <Cms>
                <ContactUsBox>
                  <Form autoComplete="off" onSubmit={onSubmit}>
                    <TitleText>Contact Us</TitleText>

                    <Box
                      sx={{
                        '& .MuiTextField-root': {m: 1, width: matches ? '30ch' : '25ch'},
                        '& label.Mui-focused': {
                          color: bloc.isFormValid ? baseTheme.colors.BorderColorLight_new : baseTheme.colors.__c_errorText,
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: bloc.isFormValid ? baseTheme.colors.BorderColorLight_new : baseTheme.colors.__c_errorText,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: bloc.isFormValid ? baseTheme.colors.BorderColorLight_new : baseTheme.colors.__c_errorText,
                          },
                        },
                      }}
                    >
                      <CustomStyleInput>
                        <CustomTextField maxRows={1} {...bloc.name} label={'Name'} />
                        <CustomTextField maxRows={1} {...bloc.email} label={'Email'} />
                        <CustomTextField maxRows={4} {...bloc.comment} label={'Comment'} />
                      </CustomStyleInput>
                    </Box>

                    <Recaptcha ref={recaptchaRef} id={recaptchaComponentId} />

                    <CustomStyleButton>
                      <Button
                        type="submit"
                        style={{
                          width: '100%',
                          height: '50px',
                          color: bloc.isFormValid
                            ? baseTheme.colors.__c_white
                            : baseTheme.colors.__c_errorText,
                          border: `1px solid ${bloc.isFormValid ? baseTheme.colors.__c_transparent : baseTheme.colors.__c_errorText}`,
                          borderRadius: 8,
                          background: bloc.isFormValid
                            ? baseTheme.colors.__с_light_blue_general_new
                            : baseTheme.colors.__c_white,
                          fontSize: '16px',
                          fontWeight: '500',
                          textTransform: 'uppercase'
                        }}
                        variant={'outlined'}
                        disabled={!bloc.isFormValid}>
                        Submit
                      </Button>
                    </CustomStyleButton>
                  </Form>
                </ContactUsBox>
              </Cms>
            </ContainerContactUs>
          </ContactUsStyle>
        </GoogleReCaptchaProvider>
      )}
    </Observer>
  );
};

const BaseFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContactUsStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContainerContactUs = styled(BaseFlex)`
  width: 1110px;
  margin: 20px 0;
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 60px;
  justify-content: space-around;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    width: ${baseTheme.braickpoints.tabletM};
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    flex-direction: column;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileM}) {
    width: ${baseTheme.braickpoints.mobileS};
    padding: 0 16px;
  }
`;

const CmsImg = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
`;

const Cms = styled(BaseFlex)`
  display: flex;
  width: 50%;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    width: 100%;
  }
`;

const ContactUsBox = styled(BaseFlex)`
  min-height: 500px;
  background-color: ${baseTheme.colors.__c_white};
`;

const Form = styled.form``;

const TitleText = styled.h3`
  margin-bottom: 20px;
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  text-align: left;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    text-align: center;
  }
`;

const CustomStyleInput = styled(BaseFlex)``;

const CustomStyleButton = styled(BaseFlex)`
  margin: 16px 0;
  padding: 0 7px;
`;

const ImgPhoneBox = styled.div`
  width: 420px;
  background-image: url(${require('../../assets/ellipse_background.png')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: ${baseTheme.braickpoints.laptop}) {
    background-image: unset;
    width: auto;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    display: none;
  }
`;

const ImgPhone = styled.img`
  height: 690px;

  @media (max-width: ${baseTheme.braickpoints.laptopL}) {
    height: 590px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    height: 490px;
  }
  // @media (max-width: ${baseTheme.braickpoints.tabletM}) {
  //   display: none;
  // };
`;
