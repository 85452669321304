import { useParams } from 'react-router';
import { PatientProfileBloc } from './patient-profile.bloc';
import { useBloc } from '../../ioc/index';
import { Observer } from 'mobx-react';
import Container from '@mui/material/Container';
import styled from 'styled-components';
import { baseTheme } from '../../GlobalStyle/GlobalStyle';

export const PatientProfilePage = () => {
  const params = useParams();
  const bloc = useBloc(PatientProfileBloc, params);

  return (
    <Observer>
      {() => {
        if (bloc.loaded == null) return <></>;
        if (bloc.loaded === false) {
          return (
            <MainWrapper>
              <Container
                maxWidth="sm"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}>
                <MessageComponent>
                  <ErrorText>Error occured! Invalid credentials</ErrorText>
                </MessageComponent>
                <StripContainer />
              </Container>
            </MainWrapper>
          );
        } else {
          return (
            <MainWrapper>
              <Container
                maxWidth="sm"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}>
                <MessageComponent>
                  <MainText>
                    Thank you, {bloc.firstname} {bloc.lastname}
                  </MainText>
                  <SecondaryText>Your email was verified!</SecondaryText>
                </MessageComponent>
                <StripContainer />
              </Container>
            </MainWrapper>
          );
        }
      }}
    </Observer>
  );
};

const MainWrapper = styled.div`
  margin-top: 400px;
`;
const MessageComponent = styled.div`
  font-size: 22px;
  color: ${baseTheme.colors.__c_dark_black_basic};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const StripContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid ${baseTheme.colors.__c_dark_purple_general};
  width: 400px;
  margin-top: 10px;
`;
const MainText = styled.div`
  color: ${baseTheme.colors.__с_turquoise_general};
  font-size: 25px;
`;
const SecondaryText = styled.div`
  color: ${baseTheme.colors.__c_dark_black_basic};
  font-size: 22px;
  margin-top: 5px;
`;
const ErrorText = styled.div`
  color: ${baseTheme.colors.__c_errorText};
  font-size: 25px;
  margin-top: 5px;
`;
