/* eslint-disable no-undef */
import { Container } from 'inversify';

import { type IHttpTransport, HttpTransportSymbol } from '@ts-awesome/request';

import { WebHttpTransport } from '../utils/transport';
import {
  UserApi,
  UserApiSymbol,
  type IUserApi,
  ContactUsApi,
  ContactUsApiSymbol,
  type IContactUsApi
} from '../api';
import { type IUserStore, UserStore, UserStoreSymbol } from '../stores';
import { AuthStore, AuthStoreSymbol, type IAuthStore } from 'stores/auth.store';
import {
  AuthService,
  AuthServiceSymbol,
  type IAuthService,
  UserService,
  UserServiceSymbol,
  type IUserService,
  ContactUsService,
  ContactUsServiceSymbol,
  type IContactUsService
} from '../services';

// import {History, createBrowserHistory} from 'history';

export function setUpRootIoC(container: Container): void {
  console.log('envs', JSON.stringify(process.env, null, 10));
  console.log('process.env.REACT_APP_BASE_API_URL', process.env.REACT_APP_BASE_API_URL);
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL ?? window.location.origin;
  // const BASE_API_URL = '';//http://localhost:3000';

  // const history = createBrowserHistory();
  // container.bind<History>(Symbol.for('history')).toConstantValue(history);

  const recaptchaSiteKey = process.env.REACT_RECAPTCHA_SITE_KEY ?? '';

  container.bind<IHttpTransport>(HttpTransportSymbol).toDynamicValue(({ container }) => {
    const authStore = container.get<IAuthStore>(AuthStoreSymbol);
    return new WebHttpTransport(
      console,
      BASE_API_URL,
      () => `Bearer ${authStore.token}`,
      () => authStore.clean()
    );
  });

  container.bind<string>(Symbol.for('recaptchaSiteKey')).toConstantValue(recaptchaSiteKey);

  //API
  container.bind<IUserApi>(UserApiSymbol).to(UserApi);
  container.bind<IContactUsApi>(ContactUsApiSymbol).to(ContactUsApi);

  // STORE BIND
  container.bind<IAuthStore>(AuthStoreSymbol).to(AuthStore).inSingletonScope();
  container.bind<IUserStore>(UserStoreSymbol).to(UserStore).inSingletonScope();

  //SERVICES
  container.bind<IAuthService>(AuthServiceSymbol).to(AuthService).inSingletonScope();
  container.bind<IUserService>(UserServiceSymbol).to(UserService).inSingletonScope();
  container.bind<IContactUsService>(ContactUsServiceSymbol).to(ContactUsService).inSingletonScope();
}
