import styled from 'styled-components';
import { baseTheme } from '../../GlobalStyle/GlobalStyle';

const EasyToUsePage = () => {
  return (
    <EasyToUseStyle>
      <ContainerEasyToUseStyle>
        <Cms>
          <EasyToUseBoxStyle>
            <BorderBox>
              <ImgIconPicture
                src={require('../../assets/easy_to_use.png')}
                alt="easy-to-use-icon"
              />
              <TitleText>Easy To Use</TitleText>
            </BorderBox>
          </EasyToUseBoxStyle>
        </Cms>

        <Cms>
          <EasyToUseBoxStyle>
            <BorderBox>
              <ImgIconPicture
                src={require('../../assets/licensed_healthcare.png')}
                alt="licensed_healthcare_icon"
              />
              <TitleText>
                Licensed Healthare
                <br /> Practitioner
              </TitleText>
            </BorderBox>
          </EasyToUseBoxStyle>
        </Cms>

        <Cms>
          <EasyToUseBoxStyle>
            <BorderBox>
              <ImgIconPicture
                src={require('../../assets/24_hours_per_day.png')}
                alt="support_icon"
              />
              <TitleText>24/7 Support</TitleText>
            </BorderBox>
          </EasyToUseBoxStyle>
        </Cms>
      </ContainerEasyToUseStyle>
    </EasyToUseStyle>
  );
};

export { EasyToUsePage };

const EasyToUseStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    margin-bottom: 30px;
  }
`;

const ContainerEasyToUseStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1110px;
  margin: 0;
  padding: 0 30px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    width: ${baseTheme.braickpoints.tabletM};
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    flex-direction: column;
    padding: 0;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileL}) {
    width: ${baseTheme.braickpoints.mobileS};
  }
`;

const Cms = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    flex-direction: column;
  }
`;

const EasyToUseBoxStyle = styled.div`
  align-item: center;
  text-align: center;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    margin-bottom: 20px;
  }
`;

const ImgIconPicture = styled.img`
  height: 83px;
`;

const TitleText = styled.h2`
  margin-top: 20px;
  font-size: 16px;
  color: ${baseTheme.colors.textDarkGray_new};
  text-align: center;
  font-size: 24px;
  font-weight: 600;

  @media (max-width: ${baseTheme.braickpoints.laptop}) {
    font-size: 20px;
  }
`;

const BorderBox = styled.div`
  border: 1px solid var(--CTA---Aqua-Squeeze, #e5f4f2);
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(51, 102, 255, 0.05);
  border-radius: 20px;
  width: 310px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: stretch;

  @media (max-width: ${baseTheme.braickpoints.laptop}) {
    width: 255px;
    height: 210px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    width: 240px;
    height: 190px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    width: 270px;
    height: 190px;
  }
`;
