import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { User } from '../models';

export interface IUserStore {
  readonly currentUser: Readonly<User> | null;
  setCurrentUser(current: User): void;
  setCurrentDoctor(doctor: User): void;
}

export const UserStoreSymbol = Symbol.for('IUserStore');

@injectable()
export class UserStore implements IUserStore {
  @observable
  private _currentUser: User | null = null;
  private _updatedDoctor: User | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setCurrentUser(current: User) {
    this._currentUser = current;
  }

  get currentUser() {
    return this._currentUser;
  }

  @action
  setCurrentDoctor(doctor: User) {
    return (this._updatedDoctor = doctor);
  }

  get CurrentDoctor() {
    return this._updatedDoctor;
  }
}
