import { type IBloc } from '../../ioc/index';
import { injectable, inject } from 'inversify';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { AuthStoreSymbol, type IAuthStore, type IUserStore, UserStoreSymbol } from '../../stores';
import { type IUserService, UserServiceSymbol } from '../../services';

export interface IPatientProfileBloc {
  readonly firstname: string | null
  readonly lastname: string | null
  readonly loaded: boolean | null
}

@injectable()
export class PatientProfileBloc implements IPatientProfileBloc, IBloc<any> {

  @observable private _firstname: string | null = null;
  @observable private _lastname: string | null = null;
  @observable private _loaded: boolean | null = null;

  @inject(AuthStoreSymbol) private readonly authStore!: IAuthStore;
  @inject(UserStoreSymbol) private readonly userStore!: IUserStore;
  @inject(UserServiceSymbol) private readonly userServices!: IUserService;

  constructor() {
    makeObservable(this);
  }

  async mount(props: any) {
    try {
      this.authStore.set(props.token);
      await this.userServices.loadMe();

      const patients = this.userStore.currentUser?.patients;
      if (!patients || patients.length === 0) {
        throw new Error('Patients array is empty');
      }

      const { firstName, lastName } = patients[0];

      runInAction(() => {
        this._firstname = firstName;
        this._lastname = lastName;
        this._loaded = true;
      });
    } catch (e) {
      runInAction(() => {
        this._loaded = false;
      });
      console.error('error: ', e);
    }
  }
  @computed get firstname(): string | null {
    return this._firstname;
  }
  @computed get lastname(): string | null {
    return this._lastname;
  }
  @computed get loaded(): boolean | null {
    return this._loaded;
  }
}
