import { injectable, inject } from 'inversify';
import { AuthApiSymbol, type IAuthApi } from '../api';
import { AuthStore, type IAuthStore } from '../stores';

export interface IAuthService {
  signIn(email: string, password: string): Promise<void>;
  singOut(): void;
}

export const AuthServiceSymbol = Symbol.for('IAuthService');

@injectable()
export class AuthService implements IAuthService {
  @inject(AuthApiSymbol)
  private authApi!: IAuthApi;

  @inject(AuthStore)
  private authStore!: IAuthStore;

  public async signIn(email: string, password: string): Promise<void> {
    const { token } = await this.authApi.singIn(email, password);
    if (token) {
      this.authStore.set(token);
    }
  }
  public singOut(): void {
    this.authStore.clean();
  }
}
