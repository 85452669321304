import { readable } from '@ts-awesome/model-reader';

export enum UserRole {
  Patient = 'patient',
  Doctor = 'doctor',
  Admin = 'admin'
}

export class Patient {
  @readable(String) uid!: string;
  @readable(String) firstName!: string;
  @readable(String) lastName!: string;
  @readable(Date) createdAt!: Date;
  @readable(Date) updatedAt!: Date;
  @readable(Date, true) birthday!: Date | null;
}

export class Doctor {
  @readable(String) firstName!: string;
  @readable(String) lastName!: string;
  @readable(String) speciality!: string;
  @readable(String, true) education!: string | null;
  @readable(Date) createdAt!: Date;
  @readable(Date) updatedAt!: Date;
  @readable(Date, true) approvedAt!: Date | null;
  @readable(String, true) approvedBy!: string | null;
}

export class User {
  @readable(String) uid!: string;
  @readable(String) phone!: string;
  @readable(String) email!: string;
  @readable(String) role!: UserRole;
  @readable(Date, true) verifiedAt!: Date | null;
  @readable(Date) createdAt!: Date;
  @readable(Date) updatedAt!: Date;

  @readable([Patient], true) patients?: Patient[] | null;
  @readable(Doctor, true) doctor?: Doctor | null;
}
