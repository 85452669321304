import { ValidateAutomate } from '@ts-awesome/validate';
import { Class } from '@ts-awesome/validate/dist/validators/model';
import { action, makeObservable, observable } from 'mobx';

export class ObservableValidateAutomate<T> extends ValidateAutomate<T> {
  constructor(model: Class<T>, onInit?: () => void) {
    super(model, onInit);
    makeObservable(this, {
      _attempted: observable,
      _state: observable.deep,
      _errors: observable.deep,
      _global: observable,
      _validate: action,
      reset: action,
      clear: action,
      set: action,
      validate: action
    } as never);
  }
}
