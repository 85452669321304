import { baseTheme } from 'GlobalStyle/GlobalStyle';
import { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import styled from 'styled-components';
import burger from '../../assets/circum_menu-burger.png';
import styles from '../burger-menu/burger-menu.module.css';

const BurgerMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <StyledMenu
        isOpen={menuOpen}
        onStateChange={handleMenuStateChange}
        right
        width={'100%'}
        customBurgerIcon={<img src={burger} alt="burger" />}
        crossButtonClassName={styles.bmBurgerButton}
        burgerBarClassName={styles.reactBurgerMenuBtn}
        noOverlay>
        <MenuItem onClick={closeMenu}>
          <NavigationButton href={'/'}>HOME</NavigationButton>
        </MenuItem>
        <MenuItem onClick={closeMenu}>
          <NavigationButton href={'#x1'}>FOR PATIENTS</NavigationButton>
        </MenuItem>
        <MenuItem onClick={closeMenu}>
          <NavigationButton href={'#x2'}>FOR DOCTORS</NavigationButton>
        </MenuItem>
        <MenuItem onClick={closeMenu}>
          <NavigationButton href={'#x3'}>CONTACT US</NavigationButton>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled(Menu)`
  background-color: ${baseTheme.colors.__c_white};
  border-right: 1px solid var(--CTA---Aqua-Squeeze, #e5f4f2);
  box-shadow: 34.854px 29.626px 48.34px 2px rgba(51, 104, 255, 0.05);
  padding-top: 2em;
  position: fixed;
  z-index: 1000;
  display: none;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    display: block;
  }
`;

const MenuItem = styled.div`
  padding: 15px;
  text-align: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  background-color: white;
`;

const NavigationButton = styled.a`
  text-decoration: none;
  color: ${baseTheme.colors.textGray_new};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  @media (max-width: ${baseTheme.braickpoints.laptopL}) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 15px;
  }
`;

export { BurgerMenu };
