import { computed, makeObservable, observable, runInAction } from 'mobx';
import { type IBloc } from '../../ioc/index';
import { injectable, inject } from 'inversify';
import { AuthStoreSymbol, type IAuthStore, type IUserStore, UserStoreSymbol } from '../../stores';
import { type IUserService, UserServiceSymbol } from '../../services';
import { ObservableValidateAutomate } from '../../utils/observable-validation-automate';
import { readable } from '@ts-awesome/model-reader';
import { presence, validate, length, alphaNumeric, type } from '@ts-awesome/validate';
import { type FieldProps } from '../../components/custom-text-field/custom-text-field';
import { IUpdateDoctorModel } from '../../api';
import { type IUploader } from '../../components/custom-uploader/custom-uploader';
import { file } from '../../utils/file-validator';

export interface IDoctorProfileBloc {
  readonly firstname: FieldProps;
  readonly lastname: FieldProps;
  readonly education: FieldProps;
  readonly speciality: FieldProps;
  readonly bio: FieldProps;
  readonly photo: IUploader;
  readonly document: IUploader;
}

const bio = '  Pediatric, gynecological, and urological specialist with 8 years of experience. I specialize in providing high-quality medical care and focus on the development of traditional medicine.\n';

class DoctorProfileInputModel implements IUpdateDoctorModel {
  @validate([presence({allowEmpty: false}), length({minimum: 3}), alphaNumeric()])
  @readable(String) public firstName!: string;

  @validate([presence({ allowEmpty: false }), length({ minimum: 3 }), alphaNumeric()])
  @readable(String)
  public lastName!: string;

  @validate([presence()])
  @readable(String)
  public education!: string;

  @validate([presence({allowEmpty: false}), length({minimum: 2})])
  @readable(String) public speciality!: string;

  @validate([presence({allowEmpty: false}), length({minimum: 2})])
  @readable(String) public bio!: string;

  @validate([type('object'), presence(), file()])
  @readable(Object)
  public photo!: File;

  @validate([type('object'), presence(), file()])
  @readable(Object) public document!: File;

}

@injectable()
export class DoctorProfileBloc implements IDoctorProfileBloc, IBloc<any> {

  @observable private _submitted: boolean = false;
  @observable private _error: boolean = false;
  @observable private _errorMessage: string = '';
  private _validator = new ObservableValidateAutomate(DoctorProfileInputModel);

  @inject(AuthStoreSymbol)
  private readonly authStore!: IAuthStore;

  @inject(UserStoreSymbol)
  private readonly userStore!: IUserStore;

  @inject(UserServiceSymbol)
  private readonly userServices!: IUserService;

  constructor() {
    makeObservable(this);
  }

  async handleSubmit() {
    if (!this._validator.validate()) {
      throw new Error('invalid doctor profile model');
    }

    try {
      const data = this._validator.read();
      console.log('submit doctor data: ', data);
      await this.userServices.updateDoctor(data);
      runInAction(() => {
        this._submitted = true;
      });
      this._validator.reset();
    } catch (err) {
      console.error(err);
      runInAction(() => {
        this._error = true;
        this._errorMessage = 'Something went wrong on our side. Your documents were not sent. Please contact the support';
      });
    } finally {
    }
  }

  async mount(props: any) {
    try{
      this.authStore.set(props.token);
      await this.userServices.loadMe();
      const doctor = this.userStore.currentUser?.doctor;
      if (!doctor) {
        throw new Error('missing doctor')
      }
      let {firstName, lastName, speciality, education} = doctor;
      this._validator
        .reset()
        .update({firstName, lastName, speciality, education: education ?? '', bio});
    } catch(err){
      runInAction(() => {
        this._error = true;
        this._errorMessage = 'Invalid link. Please request a new verification letter from your mobile app';
      });
    } 
  }
  @computed
  get firstname(): FieldProps {
    return {
      value: this._validator.values.firstName ?? '',
      errorText: this._validator.errors.firstName,
      onChange: this._validator.update.firstName
    };
  }

  @computed
  get lastname(): FieldProps {
    return {
      value: this._validator.values.lastName ?? '',
      errorText: this._validator.errors.lastName,
      onChange: this._validator.update.lastName
    };
  }

  @computed
  get education(): FieldProps {
    return {
      value: this._validator.values.education ?? '',
      errorText: this._validator.errors.education,
      onChange: this._validator.update.education
    };
  }

  @computed
  get speciality(): FieldProps {
    return {
      value: this._validator.values.speciality ?? '',
      errorText: this._validator.errors.speciality,
      onChange: this._validator.update.speciality
    };
  }

  @computed
  get bio(): FieldProps {
    return {
      value: this._validator.values.bio ?? '',
      errorText: this._validator.errors.bio,
      onChange: this._validator.update.bio
    };
  }


  @computed
  get photo(): IUploader {
    return {
      onChange: this._validator.update.photo,
      value: this._validator.values.photo,
      accept: 'image/png, image/jpeg',
      title: 'Upload Photo *',
      errorText: this._validator.errors.photo
    };
  }


  @computed
  get document(): IUploader {
    return {
      onChange: this._validator.update.document,
      value: this._validator.values.document,
      accept: '.pdf',
      title: 'Upload Document *',
      errorText: this._validator.errors.document
    };
  }

  @computed
  get submitted(): boolean {
    return this._submitted;
  }
  @computed
  get doctorName() {
    const firstName = this._validator.values.firstName || 'Name';
    return `${firstName}`;
  }
  @computed
  get doctorSurname() {
    const lastName = this._validator.values.lastName || 'Surname';
    return `${lastName}`;
  }

  @computed
  get educationDoctor(){
    const educationDoctor = this._validator.values.education || 'University of Chicago Pritzker School of MedicinePediatrician';
    return `${educationDoctor}`;
  }
  @computed
  get specialityDoctor(){
    const specialityDoctor = this._validator.values.speciality || 'Pediatrician';
    return `${specialityDoctor}`;
  }
  @computed
  get bioDoctor(){
    const bioDoctor = this._validator.values.bio || `${bio}`;
    return `${bioDoctor}`;
  }

  @computed
  get error(): boolean {
    return this._error;
  }
  @computed
  get errorMessage(): string {
    return this._errorMessage;
  }

}
