import { User } from '../models';
import { inject, injectable } from 'inversify';
import { HttpTransportSymbol, type IHttpTransport } from '@ts-awesome/request';
import { type IUserStore, UserStoreSymbol } from '../stores';

export interface IUpdateDoctorModel {
  firstName: string;
  lastName: string;
  education: string;
  speciality: string;
  bio: string;
  photo: File;
  document: File;
}

export interface IUpdatePatientModel {
  firstName: string;
  lastName: string;
  birthday: string; // Date //
  document: File;
}

export interface IUserApi {
  getMe(): Promise<User>;
  putMeDoctor(data): Promise<User>;
  putDoctor(uid: string, data: IUpdateDoctorModel): Promise<User>;
  putPatient(uid: string, patientUid: string, data: IUpdatePatientModel): Promise<User>;
}

export const UserApiSymbol = Symbol.for('IUserApi');

@injectable()
export class UserApi implements IUserApi {
  @inject(HttpTransportSymbol)
  private readonly http!: IHttpTransport;

  @inject(UserStoreSymbol)
  private readonly userStore!: IUserStore;

  getMe(): Promise<User> {
    return this.http.get('/api/v1/user/me', { Model: User });
  }

  putMeDoctor(data): Promise<User> {
    return this.putDoctor('me', data);
  }

  putDoctor(
    uid: string,
    { firstName, lastName, education, speciality, bio, photo, document }: IUpdateDoctorModel
  ): Promise<User> {
    const body = new FormData();
    body.append('firstName', firstName);
    body.append('lastName', lastName);
    body.append('education', education);
    body.append('speciality', speciality);
    body.append('bio', bio);
    body.append('photo', photo, photo.name);
    body.append('document', document, document.name);

    return this.http.put(`/api/v1/user/${uid}/doctor`, { body });
  }

  putPatient(
    uid: string,
    patientUid: string,
    { firstName, lastName, birthday, document }: IUpdatePatientModel
  ): Promise<User> {
    const body = new FormData();
    body.append('firstName', firstName);
    body.append('lastName', lastName);
    body.append('birthday', birthday);
    body.append('document', document, document.name);

    return this.http.put(`/api/v1/user/${uid}/patient/${patientUid}`, { body });
  }
}
