import styled from 'styled-components';
import { baseTheme } from '../../GlobalStyle/GlobalStyle';

const AppBenefitsPatient = () => {
  return (
    <AppBenefitsPatientStyle>
      <AppBenefitsPatientInterface id={'x1'}>
        <Cms>
          <CmsColumn>
            <PageTitle>For patients</PageTitle>
            <PageSubtitle>
              Access healthcare seamlessly with our innovative app. 
              Receive gentle reminders for healthier habits and say goodbye to hospital wait times. 
              Our app brings care to your fingertips, ensuring prompt attention without queues.
            </PageSubtitle>

            <CmsRow>
              <ImgIcon src={require('../../assets/user_friendly.png')} alt="easy-to-use-icon" />
              <CardContent>
                <TitleText> User friendly </TitleText>
                <DescriptionText> Dictate and upload pictures </DescriptionText>
              </CardContent>
            </CmsRow>

            <CmsRow>
              <ImgIcon src={require('../../assets/location.png')} alt="easy-to-use-icon" />
              <CardContent>
                <TitleText>Easy communication </TitleText>
                <DescriptionText>
                  See a doctor in person. Access to doctors across all specialties. Skip waiting
                  rooms. Simple and easy communication.
                </DescriptionText>
              </CardContent>
            </CmsRow>

            <CmsRow>
              <ImgIcon src={require('../../assets/24_hours_per_day.png')} alt="easy-to-use-icon" />
              <CardContent>
                <TitleText> Available anytime </TitleText>
                <DescriptionText>
                  {' '}
                  Healthcare available anytime and anywhere regardless of your location{' '}
                </DescriptionText>
              </CardContent>
            </CmsRow>
          </CmsColumn>
        </Cms>

        <CmsImg>
          <ImgPhoneBox>
            <ImgPhone src={require('../../assets/phone_medical_care.png')} alt="mockup-watch" />
          </ImgPhoneBox>
        </CmsImg>
      </AppBenefitsPatientInterface>
    </AppBenefitsPatientStyle>
  );
};

const AppBenefitsPatientStyle = styled.div`
  width: 100%;
  height: 650px;
  display: flex;
  align-items: center;
  margin: 0 auto;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    height: 100%;
  }
`;

const AppBenefitsPatientInterface = styled.div`
  display: flex;
  align-items: center;
  width: 1100px;
  margin: 0 auto;
  padding: 70px 60px;
  justify-content: center;
  position: relative;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    flex-direction: column;
    padding: 70px 30px 0;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileM}) {
    width: 320px;
    padding: 60px 0;
  }
`;

const Cms = styled.div`
  display: flex;
  width: 65%;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    width: 100%;
  }
`;

const CmsColumn = styled.div`
  flex-direction: column;
`;

const CmsImg = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 50px;
`;

const PageTitle = styled.text`
  color: ${baseTheme.colors.textDarkGray_new};
  display: flex;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  flex-direction: column;
  margin-bottom: 8px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    font-size: 35px;
    font-weight: 600;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileM}) {
    font-size: 28px;
    font-weight: 600;
  }
`;

const PageSubtitle = styled.text`
  color: ${baseTheme.colors.textDarkGray_new};
  display: flex;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin-bottom: 25px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    font-size: 18px;
  }
`;

const CmsRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 10px;
  padding: 15px;
  border-radius: 20px;
  border: 1px solid #f0f5fd;
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(51, 102, 255, 0.05);
`;

const ImgIcon = styled.img`
  width: 50px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    width: 45px;
  }
`;

const CardContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  @media (max-width: ${baseTheme.braickpoints.mobileL}) {
    width: 200px;
  }
`;

const TitleText = styled.h2`
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin: 0 0 6px;
  margin-left: 20px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    font-size: 17px;
    font-weight: 600;
  }
`;

const DescriptionText = styled.text`
  color: ${baseTheme.colors.textDarkGray_new};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  margin-left: 20px;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    font-size: 13px;
    font-weight: 300;
  }
`;

const ImgPhoneBox = styled.div`
  width: 420px;
  background-image: url(${require('../../assets/ellipse_background.png')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0 auto;

  @media (max-width: ${baseTheme.braickpoints.laptop}) {
    background-image: unset;
    width: auto;
  }
`;

const ImgPhone = styled.img`
  height: 690px;

  @media (max-width: ${baseTheme.braickpoints.laptopL}) {
    height: 590px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    height: 490px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    display: none;
  }
`;

export { AppBenefitsPatient };
