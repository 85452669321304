import React from 'react';

import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { MainLayout } from './layouts/main-layout';
import { Terms } from './pages/terms';
import { Privacy } from './pages/privacy';
import { DoctorProfilePage } from './pages/main/doctor-profile.page';
import { PatientProfilePage } from './pages/main/patient-profile.page';

function App() {
  return (
    <BrowserRouter>
      <AllRoutes />
    </BrowserRouter>
  );
}

export default App;

function AllRoutes() {
  return (
    <Routes>
      <Route path="" element={<MainLayout />}>
        <Route path="/patient/profile/:token" element={<PatientProfilePage />} />
        <Route path="/doctor/profile/:token" element={<DoctorProfilePage />} />
        <Route path="/" element={<Home />} />
        <Route path="terms-of-service" element={<Terms />} />
        <Route path="privacy-policy" element={<Privacy />} />
      </Route>
    </Routes>
  );
}
