import styled from 'styled-components';
import { baseTheme } from '../GlobalStyle/GlobalStyle';
import { BurgerMenu } from 'components/burger-menu/burger-menu';
import { useEffect, useState } from 'react';
import ImgHeaderLogo from '../assets/logo_new.svg';

const Header = () => {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setShowBurgerMenu(window.innerWidth < 750);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <HeaderStyle>
      <ContainerHeader>
        <HeaderLogo href="/">
          <HeaderLogoStyle src={ImgHeaderLogo} alt="logo" />
        </HeaderLogo>

        <NavigationButtonsContainer>
          <NavigationButton href="/">HOME</NavigationButton>
          <NavigationButton href="#x1">FOR PATIENTS</NavigationButton>
          <NavigationButton href="#x2">FOR DOCTORS</NavigationButton>
          <NavigationButton href="#x3">CONTACT US</NavigationButton>
        </NavigationButtonsContainer>
        {showBurgerMenu && <BurgerMenu />}
      </ContainerHeader>
    </HeaderStyle>
  );
};

export { Header };

const HeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${baseTheme.colors.__c_white};
  height: max-content;
  width: 100%;
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 5000;
`;

const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  width: 1110px;
  margin: 0;
  padding: 20px;
  justify-content: space-between;

  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    width: 100%;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileM}) {
    width: ${baseTheme.braickpoints.mobileS};
    padding: 16px 5px;
  }
`;

const HeaderLogo = styled.a`
  display: flex;
  justify-content: start;
  z-index: 2000;

  @media (max-width: ${baseTheme.braickpoints.laptopL}) {
    justify-content: center;
  }
`;

const HeaderLogoStyle = styled.img`
  width: 170px;

  @media (max-width: ${baseTheme.braickpoints.laptopL}) {
    justify-content: center;
    width: 155px;
  }
  @media (max-width: ${baseTheme.braickpoints.mobileL}) {
    width: 135px;
  }
`;

const NavigationButtonsContainer = styled.div`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;

  @media (max-width: ${baseTheme.braickpoints.tabletM}) {
    display: none;
  }
`;

const NavigationButton = styled.a`
  text-decoration: none;
  color: ${baseTheme.colors.textGray_new};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-right: 30px;

  @media (max-width: ${baseTheme.braickpoints.laptopL}) {
    margin-left: 20px;
    margin-right: 20px;
  }
  @media (max-width: ${baseTheme.braickpoints.tabletL}) {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 15px;
  }
`;
