import { Auth } from '../models/auth';
import { inject, injectable } from 'inversify';
import { HttpTransportSymbol, type IHttpTransport } from '@ts-awesome/request';

export interface IAuthApi {
  singIn(email: string, password: string): Promise<Auth>;
}

export const AuthApiSymbol = Symbol.for('IAuthApi');

@injectable()
export class AuthApi {
  @inject(HttpTransportSymbol) private http!: IHttpTransport;

  public singIn(email: string, password: string): Promise<Auth> {
    if (!email) {
      throw new Error('Email is missing');
    }
    if (!password) {
      throw new Error('Password is missing');
    }
    return this.http.post<Auth>('api/v1/auth/signin', { body: { email, password } });
  }
}
