import { EasyToUsePage } from './easy-to-use/easy-to-use.page';
import { SeeADoctorPage } from './see-a-doctor/see-a-doctor.page';
import { VideoPresentationPage } from './video-presentation/video-presentation.page';
import { ContactUsPage } from './contact-us';
import { Footer, Header } from '../layouts';
import { AppBenefitsDoctor } from './app-benefits-doctor/app-benefits-doctor';
import { AppBenefitsPatient } from './app-benefits-patient/app-benefits-patient';

export function Home() {
  return (
    <>
      <Header />
      <SeeADoctorPage />
      <EasyToUsePage />
      <AppBenefitsPatient />
      <AppBenefitsDoctor />
      <VideoPresentationPage />
      <ContactUsPage />
      <Footer />
    </>
  );
}
