import { inject, injectable } from 'inversify';
import { computed, makeObservable } from 'mobx';
import { readable } from '@ts-awesome/model-reader';
import { email, length, presence, validate } from '@ts-awesome/validate';

import { type IBloc } from '../../ioc/index';
import { ContactUsServiceSymbol, type IContactUsService } from 'services';
import { type FieldProps } from '../../components/custom-text-field/custom-text-field';
import { ObservableValidateAutomate } from '../../utils/observable-validation-automate';

type TToken = string | undefined | null | void;

interface IContactUsBloc {
  readonly name: FieldProps;
  readonly email: FieldProps;
  readonly comment: FieldProps;
  readonly isFormValid: boolean;
  readonly recaptchaSiteKey: string;

  handleSubmit(token: TToken): void;
}

class ContactUsModel {
  @validate([presence({ allowEmpty: false }), length({ minimum: 3 })])
  @readable(String)
  public name!: string;

  @validate([presence({ allowEmpty: false }), email()])
  @readable(String)
  public email!: string;

  @validate([presence({ allowEmpty: false }), length({ minimum: 10 })])
  @readable(String)
  public comment!: string;
}

@injectable()
export class ContactUsBloc implements IContactUsBloc, IBloc<any> {
  @inject(ContactUsServiceSymbol)
  private readonly contactUsService!: IContactUsService;

  @inject(Symbol.for('recaptchaSiteKey'))
  private readonly _recaptchaSiteKey!: string;

  private _validator = new ObservableValidateAutomate(ContactUsModel);

  constructor() {
    makeObservable(this);
  }

  async handleSubmit(token: TToken) {
    if (!this._validator.validate() || !token) {
      return;
    }

    const data = this._validator.read();

    try {
      await this.contactUsService.submit({ ...data, token });
    } catch (err) {
      console.log(err);
    }

    this._validator.update({ name: '', email: '', comment: '' }).reset();
  }

  async mount() {
    this._validator.reset();
  }

  @computed
  get name(): FieldProps {
    return {
      value: this._validator.values.name || '', // < || '' > is used to solve the problem of correct label behavior when the input value is set programmatically
      errorText: this._validator.errors.name,
      onChange: this._validator.update.name
    };
  }

  @computed
  get email(): FieldProps {
    return {
      value: this._validator.values.email || '', // same
      errorText: this._validator.errors.email,
      onChange: this._validator.update.email
    };
  }

  @computed
  get comment(): FieldProps {
    return {
      value: this._validator.values.comment || '', // same
      errorText: this._validator.errors.comment,
      onChange: this._validator.update.comment
    };
  }

  @computed
  get isFormValid(): boolean {
    return this._validator.valid;
  }

  @computed
  get recaptchaSiteKey(): string {
    return this._recaptchaSiteKey;
  }
}
