import React, { forwardRef, memo, ReactNode, useImperativeHandle } from 'react';
import styled, { css } from 'styled-components';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type TRecaptchaProps = {
  id: string;
  visible?: boolean;
  children?: ReactNode;
};

export type TRecaptchaRef = {
  execute: () => Promise<string | void>;
};

export const Recaptcha = memo(
  forwardRef<TRecaptchaRef, TRecaptchaProps>(({ id, children, visible }, ref) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    useImperativeHandle(ref, () => ({
      execute: async (): Promise<string | void> => {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }

        return await executeRecaptcha();
      }
    }));

    return (
      <Wrap>
        <CustomStyleRecaptcha id={id} visible={visible} />
        {children}
      </Wrap>
    );
  })
);

type TCustomStyleRecaptcha = {
  id: string;
  visible?: boolean;
};

const Wrap = styled.div``;

const CustomStyleRecaptcha = styled.div<TCustomStyleRecaptcha>`
  ${({ visible }) => css`
    display: ${visible ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    ${!visible &&
    css`
      margin: 0;
      padding: 0;
      width: 0;
      height: 0;
      opacity: 0;
    `}
  `}
`;
